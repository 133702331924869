interface FormInputProps {
  label: string;
  name: string;
  type?: string;
  autoComplete?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  helpText?: string;
  value?: string;
  placeholder?: string;
  max?: string;
  min?: string;
}

export default function FormInput({
  label,
  name,
  type,
  autoComplete,
  required,
  onChange,
  helpText,
  value,
  placeholder,
  ...rest
}: FormInputProps) {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <input
          {...rest}
          id={name}
          name={name}
          type={type || "text"}
          autoComplete={autoComplete}
          required={required}
          value={value}
          placeholder={placeholder}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mm-purple focus:border-mm-purple sm:text-sm"
          onChange={onChange}
        />
        {helpText && (
          <p className="mt-2 text-sm text-gray-500" id="navn-description">
            {helpText}
          </p>
        )}
      </div>
    </div>
  );
}
