import {
  Resolver as GraphCacheResolver,
  UpdateResolver as GraphCacheUpdateResolver,
  OptimisticMutationResolver as GraphCacheOptimisticMutationResolver,
  StorageAdapter as GraphCacheStorageAdapter,
} from "@urql/exchange-graphcache";
import { IntrospectionData } from "@urql/exchange-graphcache/dist/types/ast";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
};

export type Mutation = {
  __typename?: "Mutation";
  createStripeCheckoutBillingPortalUrl?: Maybe<Scalars["String"]>;
  createStripeCheckoutSession?: Maybe<Scalars["String"]>;
  onboardUser?: Maybe<User>;
  updateUser?: Maybe<User>;
};

export type MutationCreateStripeCheckoutBillingPortalUrlArgs = {
  userId: Scalars["String"];
};

export type MutationCreateStripeCheckoutSessionArgs = {
  plan: PaidPlan;
  userId: Scalars["String"];
};

export type MutationOnboardUserArgs = {
  dueDate: Scalars["String"];
  name: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationUpdateUserArgs = {
  dueDate?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export enum PaidPlan {
  Free = "free",
  Pro = "pro",
}

export type Query = {
  __typename?: "Query";
  currentUser?: Maybe<User>;
};

export type User = {
  __typename?: "User";
  dateWeek?: Maybe<Scalars["String"]>;
  daysIntoPregnancy?: Maybe<Scalars["Int"]>;
  dueDate?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  onboarded: Scalars["Boolean"];
  paidPlan?: Maybe<PaidPlan>;
};

export type WithTypename<T extends { __typename?: any }> = {
  [K in Exclude<keyof T, "__typename">]?: T[K];
} & { __typename: NonNullable<T["__typename"]> };

export type GraphCacheKeysConfig = {
  User?: (data: WithTypename<User>) => null | string;
};

export type GraphCacheResolvers = {
  Query?: {
    currentUser?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      WithTypename<User> | string
    >;
  };
  User?: {
    dateWeek?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    daysIntoPregnancy?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    dueDate?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    email?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    onboarded?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    paidPlan?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      PaidPlan | string
    >;
  };
};

export type GraphCacheOptimisticUpdaters = {
  createStripeCheckoutBillingPortalUrl?: GraphCacheOptimisticMutationResolver<
    MutationCreateStripeCheckoutBillingPortalUrlArgs,
    Maybe<Scalars["String"]>
  >;
  createStripeCheckoutSession?: GraphCacheOptimisticMutationResolver<
    MutationCreateStripeCheckoutSessionArgs,
    Maybe<Scalars["String"]>
  >;
  onboardUser?: GraphCacheOptimisticMutationResolver<
    MutationOnboardUserArgs,
    Maybe<WithTypename<User>>
  >;
  updateUser?: GraphCacheOptimisticMutationResolver<
    MutationUpdateUserArgs,
    Maybe<WithTypename<User>>
  >;
};

export type GraphCacheUpdaters = {
  Mutation?: {
    createStripeCheckoutBillingPortalUrl?: GraphCacheUpdateResolver<
      { createStripeCheckoutBillingPortalUrl: Maybe<Scalars["String"]> },
      MutationCreateStripeCheckoutBillingPortalUrlArgs
    >;
    createStripeCheckoutSession?: GraphCacheUpdateResolver<
      { createStripeCheckoutSession: Maybe<Scalars["String"]> },
      MutationCreateStripeCheckoutSessionArgs
    >;
    onboardUser?: GraphCacheUpdateResolver<
      { onboardUser: Maybe<WithTypename<User>> },
      MutationOnboardUserArgs
    >;
    updateUser?: GraphCacheUpdateResolver<
      { updateUser: Maybe<WithTypename<User>> },
      MutationUpdateUserArgs
    >;
  };
  Subscription?: {};
};

export type GraphCacheConfig = {
  schema?: IntrospectionData;
  updates?: GraphCacheUpdaters;
  keys?: GraphCacheKeysConfig;
  optimistic?: GraphCacheOptimisticUpdaters;
  resolvers?: GraphCacheResolvers;
  storage?: GraphCacheStorageAdapter;
};
