import { useEffect } from "react";
import { updateIntercom, loadIntercom } from "next-intercom";
import { useRouter } from "next/router";
import { useGetCurrentUserQuery } from "../client/graphql/getCurrentUser.generated";
import { HmacSHA256 } from "crypto-js";

export default function WithIntercom({ children }: { children: any }) {
  const router = useRouter();
  const [{ data }] = useGetCurrentUserQuery();

  const hash =
    data && data.currentUser
      ? HmacSHA256(
          data.currentUser.id,
          process.env.NEXT_PUBLIC_INTERCOM_VERIFICATION_TOKEN as string
        ).toString()
      : null;

  useEffect(
    () =>
      updateIntercom("update", {
        appId: "ni1jdlli",
        last_request_at: new Date().getTime() / 1000,
        user_id: data && data.currentUser && data?.currentUser.id,
        email: data && data.currentUser && data?.currentUser.email,
        user_hash: hash,
      }),
    [router.asPath, data?.currentUser?.id]
  );

  loadIntercom({
    appId: "ni1jdlli",
    initWindow: true, // default: true
    delay: 0, // default: 0  - usefull for mobile devices to prevent blocking the main thread
    user_id: data && data.currentUser && data?.currentUser.id,
    user_hash: hash,
    email: data && data.currentUser && data?.currentUser.email,
    name: data && data.currentUser && data?.currentUser.name,
  });

  return <>{children}</>;
}
