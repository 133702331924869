import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import UpgradeView from "../UpgradeView";
import { includes } from "ramda";
import { useGetCurrentUserQuery } from "../../graphql/getCurrentUser.generated";
import { shutdownIntercom } from "next-intercom";
import Onboarding from "../Onboarding";
import Link from "next/link";

interface Props {
  children: React.ReactNode;
}

const navigation = [
  { name: "Hjem", href: "/app", current: false },
  { name: "Forløb", href: "/app/moduler", current: false },
  { name: "Goodiebox", href: "/app/perks", current: false },
  { name: "Fagfolk", href: "/app/fagfolk", current: false },
  {
    name: "Facebook Gruppe",
    href: "https://www.facebook.com/groups/1063554554551616",
    external: true,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ children }: Props) {
  const router = useRouter();
  const [{ data, fetching }] = useGetCurrentUserQuery();

  if (!includes("/app", router.asPath)) {
    // Marketing page
    return <div>{children}</div>;
  }

  if (fetching) return <div>Loading...</div>;
  if (!data?.currentUser) return <Main>{children}</Main>;

  // Check if user has a paid plan, if not redirect to payment page
  if (data.currentUser && !data.currentUser.paidPlan) {
    return <UpgradeView userId={data.currentUser.id} />;
  }

  // Check if user has onboarded and given necessary info, otherwise redirect to onboarding
  if (
    data.currentUser &&
    data.currentUser.paidPlan &&
    !data.currentUser.onboarded
  ) {
    return <Onboarding />;
  }

  const logOut = () => {
    shutdownIntercom();
    router.push("/api/auth/logout");
  };

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-mm-light-rose">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-12 w-auto"
                        src="/images/mm-logo.png"
                        alt="Min Mave"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item, i) => (
                          <DesktopLink {...item} key={i} />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs rounded-full flex items-center text-sm font-medium p-2 focus:outline-none focus:ring-2 focus:ring-mm-rose">
                            <span className="sr-only">Åben brugermenu</span>
                            {data?.currentUser?.name || "Kommende mor"}
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              <Link href={"/app/settings"}>
                                <a
                                  className={classNames(
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Min Profil
                                </a>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <div
                                onClick={logOut}
                                className={classNames(
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Log ud
                              </div>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-1 rounded-md text-mm-purple  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mm-rose">
                      <span className="sr-only">Åben menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item, i) => (
                    <MobileLink {...item} key={i} />
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-mm-rose">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">🤰</div>
                    <div className="ml-3">
                      <div className="text-sm font-medium text-black">
                        {data?.currentUser?.name || "Gæst"}
                      </div>
                      <div className="text-xs font-medium text-gray-600">
                        {data?.currentUser?.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <Disclosure.Button
                      as="a"
                      href={"/app/settings"}
                      className="block px-3 py-2 rounded-md text-sm font-medium text-black hover:bg-mm-rose hover:bg-opacity-75"
                    >
                      Min Profil
                    </Disclosure.Button>

                    <Disclosure.Button
                      as="div"
                      onClick={logOut}
                      className="block px-3 py-2 rounded-md text-sm font-medium text-black hover:bg-mm-rose hover:bg-opacity-75"
                    >
                      Log ud
                    </Disclosure.Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <main>
          <div className="mx-auto">
            {/* Replace with your content */}
            {children}
            {/* /End replace */}
          </div>
        </main>
      </div>
    </>
  );
}

const Main = ({ children }: any) => (
  <main className="flex-1">
    <div className="">{children}</div>
  </main>
);

const DesktopLink = ({
  href,
  name,
  external,
}: {
  href: string;
  name: string;
  external?: boolean;
}) => {
  return external ? (
    <a
      href={href}
      className={
        "text-black hover:bg-mm-rose hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium"
      }
      target="_blank"
    >
      {name}
    </a>
  ) : (
    <Link href={href}>
      <a
        className={
          "text-black hover:bg-mm-rose hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium"
        }
        aria-current={undefined}
      >
        {name}
      </a>
    </Link>
  );
};

const MobileLink = ({
  name,
  href,
  external,
}: {
  name: string;
  href: string;
  external?: boolean;
}) => {
  return (
    <Disclosure.Button
      as="a"
      href={href}
      className={
        "text-black hover:bg-mm-rose hover:bg-opacity-75 block px-3 py-2 rounded-md text-sm font-medium"
      }
      aria-current={undefined}
      target={external ? "_blank" : ""}
    >
      {name}
    </Disclosure.Button>
  );
};
