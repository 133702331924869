import * as Types from "./types.generated";

import gql from "graphql-tag";
import * as Urql from "urql";
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OnboardUserMutationVariables = Types.Exact<{
  name: Types.Scalars["String"];
  dueDate: Types.Scalars["String"];
  userId: Types.Scalars["String"];
}>;

export type OnboardUserMutation = {
  __typename?: "Mutation";
  onboardUser?: {
    __typename?: "User";
    id: string;
    name?: string | null;
    email: string;
    dueDate?: string | null;
    onboarded: boolean;
  } | null;
};

export const OnboardUserDocument = gql`
  mutation OnboardUser($name: String!, $dueDate: String!, $userId: String!) {
    onboardUser(name: $name, userId: $userId, dueDate: $dueDate) {
      id
      name
      email
      dueDate
      onboarded
    }
  }
`;

export function useOnboardUserMutation() {
  return Urql.useMutation<OnboardUserMutation, OnboardUserMutationVariables>(
    OnboardUserDocument
  );
}
