import { Toaster } from "react-hot-toast";
import type { AppProps } from "next/app";
import { Provider } from "urql";
import { client } from "../client/graphql/client";
import Layout from "../client/components/Layout";

import "../styles/globals.css";
import Head from "next/head";
import WithIntercom from "../lib/with-intercom";
function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <Provider value={client}>
      <Head>
        <title>Min Mave Graviditetsforløb</title>
        <meta
          property="og:title"
          content="Min Mave Graviditetsforløb"
          key="title"
        />
        <meta name="application-name" content="Min Mave Graviditetsforløb" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Min Mave" />
        <meta name="description" content="Min Maves graviditetsforløb" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#b297b4" />

        <link rel="apple-touch-icon" href="/icons/icon-512x512.png" />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/icons/icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/icons/icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="/icons/icon-192x192.png"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/apple_splash_2048.png"
          sizes="2048x2732"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/apple_splash_1668.png"
          sizes="1668x2224"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/apple_splash_1536.png"
          sizes="1536x2048"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/apple_splash_1125.png"
          sizes="1125x2436"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/apple_splash_1242.png"
          sizes="1242x2208"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/apple_splash_750.png"
          sizes="750x1334"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/apple_splash_640.png"
          sizes="640x1136"
        />
        <link rel="manifest" href="/manifest.json" />

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <link rel="shortcut icon" href="/favicon.ico" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Min Mave Graviditetsforløb" />
        <meta property="og:description" content="Min Mave graviditetsforløb" />
        <meta property="og:site_name" content="Min Mave" />
        <meta property="og:url" content="https://forloeb.min-mave.dk" />
        <meta
          property="og:image"
          content="https://forloeb.min-mave.dk/icons/icon-512x512.png"
        />
      </Head>
      <WithIntercom>
        <Layout>
          <Component {...pageProps} />
          <Toaster position="top-center" />
        </Layout>
      </WithIntercom>
    </Provider>
  );
}

export default CustomApp;
