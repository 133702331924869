import dayjs from "dayjs";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useGetCurrentUserQuery } from "../../graphql/getCurrentUser.generated";
import { useOnboardUserMutation } from "../../graphql/onboardUser.generated";
import FormInput from "../ui/FormInput";

export default function Onboarding() {
  const [{ data, fetching, error }] = useGetCurrentUserQuery();
  const router = useRouter();
  const [, onboardUser] = useOnboardUserMutation();
  const [name, setName] = useState<string>("");
  const [dueDate, setDueDate] = useState<string>("");
  const currentUser = data?.currentUser;

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>{error.message}</p>;

  if (!currentUser) {
    if (process.browser) router.push("/login");
    return (
      <p>
        Redirecting to <Link href="/login">/login</Link>
        ...
      </p>
    );
  }

  const submitOnboardedUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!name || !dueDate || !currentUser) return;

    const onboardedUser = await onboardUser({
      name,
      dueDate,
      userId: currentUser.id,
    });

    if (!onboardedUser) return;

    router.push("/app/moduler");
  };

  return (
    <div className="h-screen bg-mm-light-rose flex flex-col justify-center px-2">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-16 w-auto"
          src="/images/mm-logo.png"
          alt="Min Mave"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-mm-purple">
          Velkommen til dit graviditetsforløb
        </h2>
        <p className="mt-4 text-center text-base text-black font-medium">
          Vi har brug for lidt mere viden så vi kan skræddersy forløbet til dig.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={submitOnboardedUser}>
            <FormInput
              name="navn"
              type="text"
              autoComplete="name"
              label="Navn"
              helpText="Hvad skal vi kalde dig?"
              required
              onChange={(e) => setName(e.target.value)}
              value={name}
            />

            <FormInput
              name="terminsdato"
              type="date"
              label="Terminsdato"
              helpText="Vi bruger den til at vise dig det mest relevante indhold for
              hvor, du er, i din graviditet."
              required
              placeholder="DD-MM-YYYY"
              onChange={(e) => setDueDate(e.target.value)}
              value={dueDate}
              min={dayjs().subtract(1, "day").format("YYYY-MM-DD")}
              max={dayjs().add(9, "month").format("YYYY-MM-DD")}
            />

            <div>
              <button
                type="submit"
                disabled={!name || !dueDate}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white disabled:bg-gray-300 disabled:hover:opacity-100 bg-mm-purple hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mm-purple"
              >
                Kom i gang
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
