import { loadStripe } from "@stripe/stripe-js";
import { useCreateStripeCheckoutSessionMutation } from "../../graphql/createStripeCheckoutSession.generated";
import { PaidPlan } from "../../graphql/types.generated";

const PUBLIC_STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_API_KEY;

/**
 * When clicked, redirects the user to their a Stripe Checkout session for the to upgrade to the paid plan
 */
export default function UpgradeView({ userId }: { userId: string }) {
  const [, createStripeCheckoutSession] =
    useCreateStripeCheckoutSessionMutation();

  const redirectToCheckout = () => {
    if (!PUBLIC_STRIPE_API_KEY)
      throw new Error(
        "Please define the NEXT_PUBLIC_STRIPE_PUBLIC_API_KEY env var."
      );

    Promise.all([
      loadStripe(PUBLIC_STRIPE_API_KEY),
      createStripeCheckoutSession({
        plan: PaidPlan.Pro,
        userId,
      }).then(({ data }) => data?.createStripeCheckoutSession),
    ]).then(([stripe, sessionId]) => {
      if (!stripe || !sessionId) return;

      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
    <div className="bg-mm-light-rose h-screen flex items-center text-center justify-center">
      <div className="max-w-2xl px-2">
        <h2 className="text-3xl font-extrabold text-mm-purple sm:text-4xl">
          Vi er glade for at se dig
        </h2>
        <p className="mt-8 text-center text-base font-medium leading-normal text-black max-w-lg mx-auto">
          Vores formål er at bidrage til en tryg og sjov graviditet og fødsel.
          Derfor har vi skabt et forløb, som er tilpasset dig og din graviditet.{" "}
          <br />
          <br />
          <span className="font-bold">Velkommen til dit livs rejse.</span>
        </p>
        <button
          onClick={redirectToCheckout}
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-mm-purple sm:w-auto"
        >
          Klik her for at komme i gang
        </button>
        <p className="mt-8 text-center text-base font-medium leading-normal text-black max-w-lg mx-auto">
          Du vil blive bedt om at tilføje dit kort. Vi trækker <i>ingen</i>{" "}
          penge uden din tilladelse på forhånd.
        </p>
      </div>
    </div>
  );
}
